import NoResults from '../../img/noResults.svg';
import './index.scss';
// remove custom styles once all tables where used have been rebuilt
const EmptyResults = ({line1, line2, filterEnabled=false}) => {
  return (
    <div className={`empty-results ${filterEnabled ? '' : 'empty-results--no-results'}`}>
      <img src={NoResults} alt="No results found" className="empty-results__icon" />
      <div className="empty-results__line-1">{line1}</div>
      <div className="empty-results__line-2">{line2}</div>
    </div>
  )
}

export default EmptyResults;