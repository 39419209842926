// @ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../app/slices/authSlice";
import LoginBG from "../../img/loginBg.png";
import "./index.scss";

import logo from "../../img/fullLogo.svg";
import lockIcon from "../../img/lockIcon.svg";
import {Admin} from '../../fmo-api-sdk';
import { Col, Container, Row } from "react-grid-system";
import {useHistory} from "react-router";
import { getRole, AUTH_ADMIN, AUTH_INVENTORY } from "../../app/auth";

function Login() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const doLogin = async () => {
        setLoading(true);

        const data = await Admin.generateToken(username, password);
        if (data === 'error') {
            setError(true);
            setLoading(false);
        } else {
            dispatch(login(data));
        }
    }

    return (
        <Container fluid className="loginPage" style={{ background: `url(${LoginBG}) no-repeat right` }}>
            <Row>
                <Col sm={12} md={5} xl={4} className="login-left">

                    <img
                        src={logo}
                        className="fmoLogo"
                        alt="FMO logo"
                    />
                    <div className="login-content">
                        <div className="top">
                            <img src={lockIcon} alt="lock icon" />
                            <h2 className="header">Customer<br/>Relationship<br/>Management</h2>
                            <div className="message">Please use your details to login.</div>
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            doLogin();
                            window.scrollTo(0, 0);
                        }}>
                            <input className={error? "inputError" : ""} type="email" placeholder="Email" onChange={e => setUsername(e.target.value)} />
                            <input className={error? "inputError" : ""} type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                            <button type="submit" disabled={loading}>{error? "Incorrect Details. Try Again." : "Login"}</button>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;
