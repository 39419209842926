import { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../button';
import AddedIcon from '../../../img/addedIconWhite.svg';
import ProspectForm from '../prospect-form';
import ProspectRibbon from '../user-ribbon';
import { Admin } from '../../../fmo-api-sdk';
import { PuffLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom';
import AssignProspectModal from '../../modals/assign-prospect';
import { useSelector } from 'react-redux';
import { getUserId } from '../../../app/auth';
import BurgerIcon from '../../../img/burgerAvatar.svg';
import { getName, hasManagerAccess } from '../../../assigneeData';
import ErrorBanner from '../prospect-form/error-banner';

const NewProspect = ({setHeaderTitle, setLeftHeaderSlot, setRightHeaderSlot}) => {
  const history = useHistory();
  const token = useSelector((state: any) => state.auth.token);
  const userId = getUserId(token);
  const staffList = useSelector((state: any) => state.staff);

  const [loading, setLoading] = useState(false);
  const [newProspectData, setNewProspectData] = useState<any>({});
  const [assignee, setAssignee] = useState(userId);
  const [assigneeModalOpen, setAssigneeModalOpen] = useState(false);
  const [formError, setFormError] = useState('');

  const isMobile = window.innerWidth < 768;
  const assigneeName = getName(staffList, assignee, 'initial');

  useEffect(() => {
    setHeaderTitle(newProspectData.name || 'Shop Name');

    window.innerWidth >= 768 && setLeftHeaderSlot(
      <Button
        onClick={() => setAssigneeModalOpen(true)}
        buttonText={`${assigneeName}.`}
        icon={BurgerIcon}
        iconAltText='Open assignee modal'
        type='tertiary'
        condensed />
    )

    setRightHeaderSlot(
      <Button
        buttonText="Add Prospect"
        icon={AddedIcon}
        iconAltText='Submit new prospect details'
        onClick={() => handleSubmitNewProspect()}
        condensed />
    )
  }, [newProspectData, assignee]);

  const formatPostcode = unformattedPostcode => {
    return unformattedPostcode.toLowerCase().replace(/\s/g, '')
  }

  const duplicateCheck = async(name, postcode) => {
    var allProspects = await Admin.getAllProspects().catch(error => {
      alert(error);
      setLoading(false);
    })
    var shopNameMatch = allProspects.map(prospect => prospect.shop_name.toLowerCase()).includes(name.toLowerCase());
    var postcodeMatch = allProspects.map(prospect => formatPostcode(prospect.postcode)).includes(formatPostcode(postcode));

    if (shopNameMatch && postcodeMatch) return true;
    else return false;
  }

  const handleSubmitNewProspect = async() => {
    setLoading(true)

    if (!newProspectData.postcode || !newProspectData.name) {
      let formErrors = '';
      if (!newProspectData.postcode) formErrors = formErrors.concat('postcode');
      if (!newProspectData.name) formErrors = formErrors.concat(" ", 'shopname');

      setFormError(formErrors);
      setLoading(false);
      return;
    }

    if (await duplicateCheck(newProspectData.name, newProspectData.postcode)) {
      setFormError('warning');
      setLoading(false);
      return;
    }

    fetch(`https://api.postcodes.io/postcodes/${newProspectData.postcode}`)
      .then((res) => res.json())
      .then((data) => {
        var tempobject = {
          ...newProspectData,
          source: 'OUTBOUND',
          lat: data.result.latitude,
          lng: data.result.longitude,
          status: hasManagerAccess(userId) ? 1 : 5
        };
        tempobject.assignee = assignee;

        Admin.addNewProspect(tempobject)
          .then(res => history.replace(`/prospect-overview/${res}`))
          .catch(error => {
            alert(error);
            setLoading(false);
          });
      })
      .catch(error => {
        alert(error);
        setLoading(false);
      });
  }

  const ValidationErroBanner = () => (
    <ErrorBanner
      type={formError}
      heading={formError === 'warning'
        ? 'Duplicate Prospect'
        : 'Missing Prospect Data'
      }
      subheading={formError === 'warning'
        ? 'Looks like this prospect already exists.'
        : 'Please complete all required fields.'
      }
      onClear={() => setFormError('')} />
  )

  return (
    <>
      <AssignProspectModal
        setClosed={() => setAssigneeModalOpen(false)}
        isOpen={assigneeModalOpen}
        setAssignee={setAssignee}
        assignee={assignee}
        shopName={newProspectData.name || 'Shop Name'} />

      <div className='new-prospect'>
        {loading && (
          <div className="spinner-border">
              <PuffLoader
                  size={75}
                  color={"#D82B31"}
                  loading={true}
              />
          </div>
        )}
        <div style={loading ? {display: 'none'} : {}}>
          <ProspectRibbon user={`${assigneeName}.`} onClick={() => setAssigneeModalOpen(true)} />

          {formError !== '' && isMobile && ValidationErroBanner()}

          <div className='new-prospect__inner'>
            {formError !== '' && !isMobile && ValidationErroBanner()}
            <ProspectForm setFormData={setNewProspectData} formErrors={formError} />
          </div>
        </div>
      </div>
    </>
  )
}

export default NewProspect;