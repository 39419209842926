const dashboardColumns = [
  {name: 'Area', value: 'postcode'},
  {name: 'Month', value: 'datestamp'},
  {name: 'Shops', value: 'total_shops'},
  {name: 'New Signups', value: 'new_signings'},
  {name: 'Cancellations', value: 'cancellations'},
  {name: 'Total', value: 'total_month_orders', group: 'orders', tags: [{tagText: 'ORDERS'}]},
  {name: 'Total', value: 'previous_year_orders', group: 'orders', tags: [{tagText: "ORDERS '24"}]},
  {name: 'New Shops', value: 'new_orders', group: 'orders', tags: [{tagText: 'ORDERS'}]},
  {name: 'New Shops', value: 'previous_year_new_orders', group: 'orders', tags: [{tagText: "ORDERS '24"}]},
  {name: 'Growth MoM', value: 'monthly_growth', group: 'orders', tags: [{tagText: 'ORDERS'}]},
  {name: 'Growth YoY', value: 'yearly_growth', group: 'orders', tags: [{tagText: 'ORDERS'}]},
  {name: 'G Listing', value: 'g_listings', group: 'levers'},
  {name: 'ePOS Primary', value: 'epos_primary', group: 'levers'},
  {name: 'SMS Sent', value: 'sms_sent', group: 'levers'},
  {name: 'Credits Used', value: 'credits_used', group: 'sms', tags: [{tagText: 'SMS'}]},
  {name: 'Added Manually', value: 'manually_added', group: 'numbers', tags: [{tagText: 'NUMBERS', tagType: 'plum'}]},
];

export default dashboardColumns;