import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Buffer } from "buffer";
import { cacheAdapterEnhancer } from 'axios-extensions';
import LRUCache from "lru-cache";
import { logout } from "../app/slices/authSlice";
import { store } from "../app/store";

const defaultCache = new LRUCache({ maxAge: 4 * 60 * 60 * 1000, max: 0});

/**
 * Creates a single instance of Axios
 */
class API {
    private axiosClient: AxiosInstance;

    constructor() {
        this.axiosClient = axios.create({
            // baseURL: "https://api.feedmeonline.co.uk",
            baseURL: "https://prd-api.actionprompt.com",
            headers: { 
                'Cache-Control': 'no-cache', 
                Authorization: `Basic ${Buffer.from("api:F34g#%G325g532").toString('base64')}`, 
                'User-Agent': 'ActionPrompt Crm' 
            },
            //@ts-ignore
            // adapter: cacheAdapterEnhancer(axios.defaults.adapter, {defaultCache})
        });

        this.axiosClient.interceptors.response.use(
          response => response,
          error => {
            const status = error.response ? error.response.status : null;

            if (status === 403) store.dispatch(logout())

            return Promise.reject(error);
          }
        )
    }

    updateConfig(config: AxiosRequestConfig) {
        //@ts-ignore
        this.axiosClient = axios.create({ ...this.axiosClient.defaults, ...config });
    }

    get Instance() {
        return this.axiosClient;
    }
}

export default (new API());
