import CheckInTimerTag from "../overview/checkin-timer-tag";
import LongLogo from '../../../img/logoLong.svg'
import './index.scss';
import { useSelector } from "react-redux";
import BurgerIcon from '../../../img/burgerAvatar.svg';

interface Props {
  checkinData?: any,
  user?: string | null,
  onClick?: () => void
}

const UserRibbon = ({checkinData = null, user = null, onClick}: Props) => {
  const userName = useSelector((state: any) => state.auth.userData);
  const parsedName = user ?? `${userName.first_name} ${userName.last_name[0]}.`

  return (
    <div onClick={onClick} className={`user-ribbon ${user ? 'user-ribbon--overview' : ''} ${checkinData?.name ? 'user-ribbon--checked-in' : ''}`}>
      <div className='user-ribbon__user-wrapper'>
        <img src={BurgerIcon} alt="Assigned agent" />
        {onClick ? (
          <>
            <div>Agent:</div>
            <div className='user-ribbon__light'>{parsedName}</div>
          </>
        ) : (
          <div>Hi, {userName.first_name}!</div>
        )}
      </div>

      {checkinData?.name
        ? <CheckInTimerTag checkinData={checkinData} />
        : <img src={LongLogo} alt="FeedMeOnline logo" />
      }
    </div>
  )
}

export default UserRibbon;