import ProgressBar from '../progress-bar';
import './index.scss';
import BurgerIcon from '../../img/burgerAvatar.svg'
import PinIcon from '../../img/mapPinFilled.svg'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Admin } from '../../fmo-api-sdk';
import { getUserId } from '../../app/auth';
import { PuffLoader } from 'react-spinners';
import ProductivityIcon from '../../img/icons/powerIcon.svg';
import GoogleIcon from '../../img/googleColour24.svg';
import FilterTabs from '../filter-tabs';
import OrderIcon from '../../img/icons/orderWhite.svg';
import LeversIcon from '../../img/icons/ordersSuccess.svg';
import Tag from '../tag';
import TimerIcon from '../../img/timer.svg';
import SuccessIcon from '../../img/greenTickFilled.svg';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import isProblemShop from '../../app/helper/reports';
import {orderBy} from "lodash";
import ProspectRibbon from '../pipeline/user-ribbon';
import StatTile from '../overview/stat-tile';
import AnalyticsIcon from '../../img/icons/zReportColour.svg'
import CaretIcon from '../../img/icons/caret.svg'
import DashboardColumns from './dashboardColumns'
import dashboardColumns from './dashboardColumns';
import AppliedFilterTag from '../applied-filter-tag';

const Dashboard = () => {
  const authData = useSelector((state: any) => state.auth);
  const user = authData?.userData;
  const userId = getUserId(authData.token);
  const history = useHistory();
  const navigate = url => history.push(url);

  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [shopData, setShopData] = useState<any[]>([]);
  const [jobData, setJobData] = useState<any>();
  const [googleTab, setGoogleTab] = useState('online');
  const [leversTab, setLeversTab] = useState('online');
  const [tableOpen, setTableOpen] = useState(false);
  const [postcodeData, setPostcodeData] = useState<any>({});

  const isMobile = window.innerWidth < 768;

  const summaryData = [
    {title: 'Cancelled'},
    {title: 'In Decline', subtitle: 'Problem Shops'},
    {title: 'Signups', subtitle: 'This Month'},
    {title: 'Total Shops'}
  ]

  const leverData = [
    {name: 'G Listing', value: 'google_business'},
    {name: 'Branding', value: 'stickers'},
    {name: 'SMS Sent', value: 'sms'},
    {name: 'Flyers', value: 'flyers'},
    {name: 'ePOS Primary', value: 'is_epos_primary'},
    {name: 'Posters', value: 'posters'},
    {name: 'FB Page', value: 'social'},
    {name: 'AP Card', value: 'has_ap_card'},
    {name: 'Menu Link', value: 'menu_link'},
    {name: 'PPC', value: 'ppc'},
    {name: 'Email', value: 'email'},
    {name: 'Push Notifications', value: 'push_notification'},
  ];

  const orderData = [
    {title: 'Today', value: 'today'},
    {title: 'This Week', value: 'this_week'},
    {title: 'This Month', value: 'this_month'},
    {title: 'Last Month', value: 'last_month'},
    {title: 'Trend', value: 'trend'},
  ]

  const leversShopData = leversTab === 'online' ? shopData.filter(shop => shop.info.wholesaler_status === '1') : shopData;
  const googleShopData = googleTab === 'online' ? shopData.filter(shop => shop.info.wholesaler_status === '1') : shopData;

  useEffect(() => {
    (async () => {
      // fetch shop data
      await Admin.getShopsManagedByAgent(userId)
        .then(res => setShopData(res))
        .catch(error => console.log(error));

      // fetch jobs
      await Admin.getAgentJobs()
        .then(res => {
          const pendingJobs = res.filter(job => !job.type.includes("complete") && job.status !== "Completed");
          const completedJobs = res.filter(job => (job.type.includes("complete") || job.status === "Completed") && moment().isSame(job.last_updated, 'month'));

          setJobData({
            pendingCount: pendingJobs.length,
            completedCount: completedJobs.length
          })
        })
        .catch(error => console.log(error));

      setLoading(false);
    })()
  }, []);

  useEffect(() => {
    if (tableOpen && !Object.keys(postcodeData).length) {
      (async () => {
        await Admin.getPostcodeBreakdown()
          .then(res => setPostcodeData(res))
          .catch(error => console.log(error));

        setTableLoading(false)
      })()
    }
  }, [tableOpen, postcodeData])

  const getUserPostcodes = () => {
    var allPostcodes = shopData.map(shop => shop.wholesaler_postcode.substring(0, 2).replace(/[^A-Z]/, ''));
    allPostcodes = [...new Set(allPostcodes)]; // removed duplicates
    var postcodeArray = [] as any;

    allPostcodes.forEach(postcode => {
      var postcodeCount = shopData.filter(shop => shop.wholesaler_postcode.substring(0, 2).replace(/[^A-Z]/, '') === postcode).length;

      postcodeArray.push({
        code: postcode,
        count: postcodeCount
      })
    })

    return orderBy(postcodeArray, [postcode => postcode.count], ['desc']);
  }

  const getPostcodeTags = () => {
    if (!shopData.length) return (
      <AppliedFilterTag
        size='small'
        text='Office HQ'
        icon={PinIcon}
        iconAlt='Pin icon' />
    )

    const shownTagsNum = isMobile ? 3 : 4
    const userPostcodes = getUserPostcodes();

    return (
      <>
        {userPostcodes.slice(0, shownTagsNum).map(postcode => {
          return (
            <AppliedFilterTag
              key={postcode.code}
              size='small'
              text={postcode.code}
              icon={PinIcon}
              iconAlt='Pin icon' />
          )
        })}

        {userPostcodes.length > shownTagsNum && <div className="dashboard__more-tag">+{userPostcodes.length - shownTagsNum} more</div>}
      </>
    )
  }

  const postcodeTable = () => (
    <div className='dashboard-table__wrapper'>
      <table className='dashboard-table'>
        <thead className='dashboard-table__header'>
          <tr>
            {DashboardColumns.map((column, index) => (
              <th key={column.value}>
                <div className={`dashboard-table__cell dashboard-table__cell--header ${index < 4 ? 'dashboard-table__cell--header-small' : column.group ? `dashboard-table__cell--${column.group}` : ''}`}>
                  {column.tags && <Tag text={column.tags[0].tagText} type={column.tags[0].tagType ?? undefined} size='x-small' />}
                  {column.name}
                </div>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {[...Array(postcodeData.last_month.area_data.length + 1).keys()].map(rowIndex => {
            const lastMonth = moment(postcodeData['last_month']['area_data'][0]?.datestamp).format('MMM').toUpperCase();
            const thisMonth = moment(postcodeData['this_month']['area_data'][0]?.datestamp).format('MMM').toUpperCase();
            const lastMonthData = !rowIndex ? postcodeData['last_month']['total'] : postcodeData['last_month']['area_data'][rowIndex - 1];
            const thisMonthData = !rowIndex ? postcodeData['this_month']['total'] : postcodeData['this_month']['area_data'][rowIndex - 1];

            return (
              <tr key={rowIndex} className='dashboard-table__row'>
                {dashboardColumns.map((column, index) => {
                  var cell1 = {
                    value: lastMonthData[column.value],
                    class: ''
                  };
                  let cell2 = {
                    value: thisMonthData?.[column.value],
                    class: ''
                  };

                  if (!cell1.value) cell1.class = 'dashboard-table__cell--warning';
                  if (!cell2.value) cell2.class = 'dashboard-table__cell--warning';

                  if (column.group === 'levers') {
                    cell1.class = `dashboard-table__cell--split ${cell1.class}`;
                    cell2.class = `dashboard-table__cell--split ${cell2.class}`;
                    cell1.value = <><div>{((cell1.value / lastMonthData.total_shops) || 0).toLocaleString(undefined, {style: 'percent', maximumFractionDigits:0})}</div><div>{cell1.value.toLocaleString('en-GB')}</div></>
                    cell2.value = <><div>{((cell2.value / thisMonthData.total_shops) || 0).toLocaleString(undefined, {style: 'percent', maximumFractionDigits:0})}</div><div>{cell2.value.toLocaleString('en-GB')}</div></>
                  } else if (column.value === 'datestamp') {
                    cell1.value = lastMonth;
                    cell2.value = thisMonth;
                    cell1.class = '';
                    cell2.class = '';
                  } else if (column.value === 'total_shops') {
                    cell2.class = cell2.value === cell1.value ? '' : cell2.value > cell1.value ? 'dashboard-table__cell--success' : 'dashboard-table__cell--warning';
                  } else if (column.value === 'cancellations') {
                    cell1.class = cell1.value <= 0 ? 'dashboard-table__cell--success' : 'dashboard-table__cell--warning';
                    cell2.class = cell2.value <= 0 ? 'dashboard-table__cell--success' : 'dashboard-table__cell--warning';
                  } else if (['new_signings', 'monthly_growth', 'yearly_growth'].includes(column.value)) {
                    cell1.class = parseInt(cell1.value) > 0 ? 'dashboard-table__cell--success' : 'dashboard-table__cell--warning';
                    cell2.class = parseInt(cell2.value) > 0 ? 'dashboard-table__cell--success' : 'dashboard-table__cell--warning';
                  }

                  return (
                    <td key={column.value}>
                      {!index ? (
                        <div className='dashboard-table__cell dashboard-table__cell--double-height'>{lastMonthData.postcode_alpha ?? 'All'}</div>
                      ) : (
                        <div className='dashboard-table__cell-stack'>
                          <div className={`dashboard-table__cell ${cell1?.class}`}>
                            {typeof cell1.value === 'object' ? cell1.value : cell1.value?.toLocaleString('en-GB')}
                          </div>
                          <div className={`dashboard-table__cell ${cell2?.class}`}>
                            {typeof cell1.value === 'object' ? cell2.value : cell2.value?.toLocaleString('en-GB')}
                          </div>
                        </div>
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )

  const panelHeader = (icon, title, subtitle='') => (
    <div className={`dashboard-panel__header ${subtitle ? '' : 'dashboard-panel__header--no-subtitle'}`}>
      <div>
        <div className="dashboard-panel__title">{title}</div>
        {subtitle && <div className="dashboard-panel__subtitle">{subtitle}</div>}
      </div>
      <div className="dashboard-panel__icon-wrapper">
        <img src={icon} alt="Panel icon" />
      </div>
    </div>
  )

  const productivityPanel = () => (
    <div onClick={() => navigate('/jobs')} className="dashboard-panel dashboard-panel--productivity dashboard-panel__content">
      {panelHeader(ProductivityIcon, 'Productivity', 'This Month')}

      <ProgressBar dividend={jobData.completedCount} divisor={jobData.pendingCount} divisorInclusive={false} />

      <div className="dashboard-panel__tag-wrapper">
        <Tag text={`${jobData.pendingCount} Pending Jobs`} icon={TimerIcon} altText='Pending jobs icon' type='highlight' size={isMobile ? 'medium' : 'large'} />
        <Tag text={`${jobData.completedCount} Completed`} icon={SuccessIcon} altText='Completed jobs icon' type='success' size={isMobile ? 'medium' : 'large'} />
      </div>
    </div>
  )

  const ordersPanel = () => (
    <div className="dashboard-panel dashboard-panel--orders dashboard-panel__content">
      {panelHeader(OrderIcon, 'Orders')}

      <div className="dashboard-panel__orders-tile-wrapper">
        {orderData.map(tile => {
          var tileValue = getSum(shopData, `orders.${tile.value}`);

          if (tile.value === 'trend') tileValue = getSum(shopData, `orders.last_month`) - getSum(shopData, `orders.prev_month`);

          return (
            <StatTile
              key={tile.value}
              heading={tile.title}
              value={tileValue.toLocaleString('en-GB')}
              type={!shopData.length ? 'disabled' : tileValue <= 0 ? 'warning' : undefined} />
          )
        })}
      </div>
    </div>
  )

  const googlePanel = () => (
    <div className="dashboard-panel">
      <FilterTabs
        tabData={[{
          ref: 'online',
          text: 'Online'
        },
        {
          ref: 'overall',
          text: 'Overall'
        }]}
        activeTab={googleTab}
        onClick={setGoogleTab} />

      <div className="dashboard-panel__content">
        {panelHeader(GoogleIcon, 'Google Listing', 'Claimed This Month')}

        <ProgressBar dividend={getSum(googleShopData, 'marketing.google_business')} divisor={googleShopData.length} />
      </div>
    </div>
  )

  const leversPanel = () => (
    <div className="dashboard-panel">
      <FilterTabs
        tabData={[{
          ref: 'online',
          text: 'Online'
        },
        {
          ref: 'overall',
          text: 'Overall'
        }]}
        activeTab={leversTab}
        onClick={setLeversTab} />

      <div className="dashboard-panel__content">
        {panelHeader(LeversIcon, 'Marketing Levers')}

        <div className="dashboard-panel__lever-wrapper">
          {leverData.map(lever => (
            <div key={lever.value} className="dashboard-panel__lever">
              {lever.name}

              <ProgressBar
                dividend={getSum(leversShopData, `marketing.${lever.value}`, lever.value === 'has_ap_card')}
                divisor={leversShopData.length}
                displayType='percent' />
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  const getSum = (shopList, sumRef, getCount=false) => {
    var splitRef = sumRef?.split('.');
    
    if (getCount) return shopList.filter(shop => shop[splitRef[0]][splitRef[1]]).length;
    return shopList.reduce((acc, shop) => acc + shop[splitRef[0]][splitRef[1]], 0);
  }

  return (
    <>
      {loading ? (
        <div className="spinner-border">
          <PuffLoader
              size={75}
              color={"#D82B31"}
              loading={true}
          />
        </div>
      ) : (
        <div className='dashboard'>
          <ProspectRibbon />

          <div className="dashboard__inner">
            <div className="dashboard-panel">
              <div className='dashboard-panel__content dashboard-panel__content--summary'>
                <div className="dashboard__summary">
                  <div className="dashboard__greeting">
                    <img src={BurgerIcon} alt="User icon" className="dashboard__user-icon" />
                    <div>Hi, {user?.first_name}!</div>
                  </div>

                  <div className="dashboard__region-wrapper">
                    <div className="dashboard__region">{shopData.length ? user?.region : 'Internal Account'}</div>
                    <div>{shopData.length ? "Here's how your region is doing." : "No assigned shops."}</div>
                  </div>

                  <div className="dashboard__postcode-tag-wrapper">
                    {getPostcodeTags()}
                  </div>
                </div>

                <div className='dashboard__summary-tile-wrapper'>
                  {summaryData.map(tile => {
                    let tileValue;
                    let tileType;

                    if (tile.title === 'Cancelled') {
                      tileValue = shopData.filter(shop => shop.info.wholesaler_status === '5').length;
                      tileType = tileValue > 0 ? 'warning' : 'success';
                    }
                    else if (tile.title === 'In Decline') {
                      tileValue = shopData.filter(shop => isProblemShop(shop)).length;
                      tileType = tileValue > 0 ? 'warning' : 'success';
                    }
                    else if (tile.title === 'Signups') {
                      tileValue = shopData?.filter(shop => moment().isSame(shop.info.sign_up_date, 'month')).length;
                      tileType = tileValue === 0 ? 'warning' : 'success';
                    }
                    else tileValue = shopData.length;

                    return (
                      <StatTile
                        key={tile.title}
                        value={tileValue.toLocaleString('en-GB')}
                        heading={tile.title}
                        subheading={tile.subtitle}
                        type={!shopData.length ? 'disabled' : tileType} />
                    )
                  })}
                </div>
              </div>

              {/* Manzoor, David, Parvase, Alistair */}
              {['132', '229', '260', '130'].includes(userId) && !!shopData.length &&
                <div>
                  <div onClick={() => setTableOpen(!tableOpen)} className={`dashboard__button ${tableOpen ? 'dashboard__button--open' : ''}`}>
                    <img src={AnalyticsIcon} alt='Analytics icon' />
                    Analytics
                    <img src={CaretIcon} alt='Caret icon' className={`${tableOpen ? 'dashboard__icon-open' : ''}`} />
                  </div>

                  {tableOpen &&
                    <>
                      {tableLoading ? (
                        <div className="spinner-border">
                          <PuffLoader
                              size={75}
                              color={"#D82B31"}
                              loading={true}
                          />
                        </div>
                      ) : (
                        postcodeTable()
                      )}
                    </>
                  }
                </div>
              }
            </div>
            
            <div className="dashboard__section-wrapper">
              <div className="dashboard__section">
                {googlePanel()}
                {leversPanel()}
              </div>
        
              <div className="dashboard__section">
                {productivityPanel()}
                {ordersPanel()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Dashboard;