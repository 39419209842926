import { useEffect, useRef, useState} from "react";
import { filter, sortBy } from "lodash";
import "./index.scss";
import {Admin} from '../../fmo-api-sdk';
import JobHistoryModal from "../modals/job-history"
import TicketCard from "../ticket-card";
import TimerIcon from '../../img/timer.svg';
import filteredIconInactive from '../../img/timer-grey.svg';
import CompletedIcon from '../../img/greenTickFilled.svg';
import CompletedIconInactive from '../../img/greyTick.svg';
import Counter from "../header-components/counter";
import { PuffLoader } from "react-spinners";
import EmptyResults from "../empty-results";
import FilterTabs from "../filter-tabs";

const AgentJobs = ({setLeftHeaderSlot, setRightHeaderSlot}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentJob, setCurrentJob] = useState<any>(null);
    const [tab, setTab] = useState<string>('todo');
    const [sort, setSort] = useState<number>(parseInt(localStorage.getItem('myJobsSorter') || '0'));
    const [autoGenJob, setAutoGenJob] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rawData, setRawData] = useState<Admin.Job[]>([]);
    const [filteredData, setFilteredData] = useState([]);
    const [sortedData, setSortedData] = useState([]);

    const ref = useRef<any>(null)
    const isMobile = window.innerWidth < 768;

    useEffect(() => {
      fetchAgentJobs();
    }, []);

    useEffect(() => {
      setLeftHeaderSlot(
        <>
          {!isMobile && headerTabs()}
          <Counter counterData={filteredData.length} />
        </>
      )
    }, [filteredData])

    useEffect(() => {
      if (!loading) {
        setTimeout(() => {
          ref.current.scrollTop = parseInt(localStorage.getItem('myJobsScrollPos') ?? '0');
        }, 0)
      }
    }, [loading]);

    useEffect(() => {
      if (tab === 'todo') return setFilteredData(filter(rawData, j => !j.type.includes("complete") && j.status !== "Completed"));

      var completedJobs = filter(rawData, j => j.type.includes("complete") || j.status === "Completed");
      completedJobs = sortBy(completedJobs, j => j.last_updated);
      setFilteredData(completedJobs.reverse().slice(0, 204));
    }, [rawData, tab]);

    useEffect(() => {
      const tempSorted = sortBy(filteredData, j => {
        if (sort === 0) return j.postcode;
        if (sort === 1) return tab === 'todo' ? j.created : j.last_updated;
        if (sort === 2) return priorityOrder[j.priority];
      });

      setSortedData(sort === 0 ? tempSorted : tempSorted.reverse());
    }, [filteredData, sort]);

    useEffect(() => {
      setRightHeaderSlot(
        <>
          <div className="my-jobs-sorter__title">Sort By</div>
          <div className="my-jobs-sorter__button-wrapper">
            <div onClick={() => rawData.length && (setSort(1), localStorage.setItem('myJobsSorter', '1'))} className={`my-jobs-sorter__button ${!rawData.length ? 'my-jobs-sorter__button--disabled' : sort === 1 ? 'my-jobs-sorter__button--active' : ''}`}>Date</div>
            <div onClick={() => rawData.length && (setSort(0), localStorage.setItem('myJobsSorter', '0'))} className={`my-jobs-sorter__button ${!rawData.length ? 'my-jobs-sorter__button--disabled' : sort === 0 ? 'my-jobs-sorter__button--active' : ''}`}>Postcode</div>
            <div onClick={() => rawData.length && (setSort(2), localStorage.setItem('myJobsSorter', '2'))} className={`my-jobs-sorter__button ${!rawData.length ? 'my-jobs-sorter__button--disabled' : sort === 2 ? 'my-jobs-sorter__button--active' : ''}`}>Priority</div>
          </div>
        </>
      );
    }, [sort, rawData])

    //prevent scrolling when modal is open    
    const preventScrolling = function (showModal) {
      if (showModal) document.body.classList.add("no-scroll");
      else document.body.classList.remove("no-scroll");
    }

    preventScrolling(showModal);

    const priorityOrder = {
      'Urgent': 4,
      'High': 3,
      'Normal': 2,
      'Low': 1,
    }

    const fetchAgentJobs = async(forceUpdate = false) => {
      setCurrentJob(null);

      await Admin.getAgentJobs({forceUpdate})
        .then(res => setRawData(res))
        .catch(error => console.log(error));

      setLoading(false);
    }

    const openModal = (job, autoGenerated) => {
      return () => {
        setCurrentJob(job);
        setShowModal(true);
        setAutoGenJob(autoGenerated);
      }
    }

    const headerTabs = () => (
      <FilterTabs
        tabData={[
          {
            ref: 'todo',
            icon: TimerIcon,
            inactiveIcon: filteredIconInactive,
            iconAlt: 'ToDo icon',
            text: 'ToDo'
          },
          {
            ref: 'completed',
            icon: CompletedIcon,
            inactiveIcon: CompletedIconInactive,
            iconAlt: 'Completed icon',
            text: 'Done'
          }
        ]}
        activeTab={tab}
        onClick={setTab}
        disabled={!rawData.length}
        header
      />
    );

    return (
      <>
        <JobHistoryModal isOpen={showModal} data={{currentJob}} autoGenerated={autoGenJob} setClosed={jobUpdated => {
            setShowModal(false);
            fetchAgentJobs(jobUpdated);
        }}/>

        <div className="agent-jobs" ref={ref} onScroll={() => localStorage.setItem('myJobsScrollPos', ref.current.scrollTop)}>
          {isMobile && headerTabs()}

          {loading ? (
            <div className="spinner-border">
                <PuffLoader
                    size={75}
                    color={"#D82B31"}
                    loading={true}
                />
            </div>
          ) : (
            <>
              <h3 className="agent-jobs__title">My Jobs</h3>

              {!rawData.length ? (
                <EmptyResults line1={'Looks like you’re all caught up!'} line2={'You have no outstanding jobs.'} />
              ) : (
                <div className="agent-jobs__card-wrapper">
                  {sortedData.map((job,idx) =>
                    <TicketCard key={idx} job={job} onClick={openModal} completed={tab === 'completed'} />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </>
    )
}

export default AgentJobs;
