import './index.scss';
import PropTypes from 'prop-types';

interface Props {
  value: number | string
  heading: string
  subheading?: string
  additionalData?: any
  type?: string
  small?: boolean
}

const StatTile = ({value, heading, type, subheading, additionalData=null, small=false}: Props) => (
  <div className={`stat-tile ${type ? `stat-tile--${type}` : ''} ${small ? 'stat-tile--small' : ''}`}>
    <div>
      <div className={`stat-tile__heading ${type ? `stat-tile__heading--${type}` : ''}`}>{heading}</div>
      {subheading && <div className={`stat-tile__subheading ${type ? `stat-tile__heading--${type}` : ''}`}>{subheading}</div>}
    </div>
{/* subheading may not need type class */}
    <div className={`stat-tile__value ${type ? `stat-tile__value--${type}` : ''}`}>{type === 'disabled' ? 'N/a' : value}</div>

    {additionalData}
  </div>
)

StatTile.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'disabled']),
}

export default StatTile;