import { Dispatch, SetStateAction } from 'react';
import './index.scss';

type TabData = {
  ref: string
  text: string
} & (
  {
    icon: string
    inactiveIcon?: string
    iconAlt: string
  } | {
    icon?: never
    inactiveIcon?: never
    iconAlt?: never
  }
);

interface Props {
  tabData: TabData[]
  activeTab: string
  onClick: Dispatch<SetStateAction<string>>
  disabled?: boolean
  header?: boolean
}

const FilterTabs = ({tabData, activeTab, onClick, disabled, header}: Props) => (
  <div className={`filter-tabs ${header ? 'filter-tabs--header' : ''}`}>
    {tabData.map((tab, index) => {
      const isActive = tab.ref === activeTab;

      return (
        <div
          key={index}
          onClick={() => onClick(tab.ref)}
          className={`filter-tabs__tab ${disabled ? 'filter-tabs__tab--disabled' : isActive ? 'filter-tabs__tab--active' : ''} ${header ? 'filter-tabs__tab--header' : ''}`}>

          {tab.icon && <img src={!isActive || disabled ? tab.inactiveIcon : tab.icon} alt={tab.iconAlt} />}
          {tab.text}
        </div>
      )
    })}
  </div>
)

export default FilterTabs;