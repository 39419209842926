import './index.scss';
import CloseIcon from '../../img/icons/closeIcon.svg'
import PropTypes from 'prop-types';
import { MouseEventHandler } from 'react';

type Props = {
  size?: string,
  text: string,
  removeFilter?: MouseEventHandler<HTMLImageElement>,
} & (
  {
    icon: string,
    iconAlt: string
  } | {
    icon?: never,
    iconAlt?: never
  }
)

const AppliedFilterTag = ({size='medium', text, removeFilter, icon, iconAlt}: Props) => {
  return (
    <div className={`applied-filter applied-filter--${size}`}>
      {icon && <img src={icon} alt={iconAlt} />}
      {text}
      {removeFilter && <img src={CloseIcon} alt='Remove filter' onClick={removeFilter} className='applied-filter__close-icon' />}
    </div>
  )
}

AppliedFilterTag.propTypes = {
  type: PropTypes.oneOf(['small', 'medium', 'large']),
}

export default AppliedFilterTag;