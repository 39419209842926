import { useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const ProgressBar = ({displayType='numbers', dividend, divisor, divisorInclusive = true}) => {
  const [progressPerc, setProgressPerc] = useState<any>(0)
  const realDivisor = divisorInclusive ? divisor : dividend + divisor;
  const rawPerc = realDivisor ? dividend / realDivisor : 0;
  const progressColour = rawPerc <= 0.30 ? '#D82B31' : rawPerc <= 0.50 ? '#F46B21' : rawPerc <= 0.75 ? '#FFA829' : '#43A564';

  setTimeout(() => {
    setProgressPerc(Math.min(rawPerc, 1).toLocaleString(undefined, {style: 'percent', maximumFractionDigits:0}));
  }, 1);

  return (
    <div>
      {displayType !== 'percent' && <div className='progress-bar__progress-number'>{progressPerc}</div>}
    
      <div className="progress-bar">
        <div className="progress-bar__bar">
          <div className="progress-bar__bar progress-bar__bar--inner" style={{width: progressPerc, backgroundColor: progressColour}}></div>
        </div>
        <div className="progress-bar__text">
          {displayType === 'percent' ? progressPerc : `${dividend}/${realDivisor}`}
        </div>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  displayType: PropTypes.oneOf(['numbers', 'percent'])
}

export default ProgressBar;