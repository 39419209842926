import { useEffect, useState } from 'react';
import Button from '../../button';
import './index.scss';
import doorIconWhite from '../../../img/icons/doorIconWhite.svg'
import doorIconThali from '../../../img/icons/doorIconThali.svg'
import matrixIcon from '../../../img/icons/matrix.svg'
import Tag from '../../tag';
import { useParams } from 'react-router-dom';
import { getReasonCode } from '../pipelineData';
import { Admin } from '../../../fmo-api-sdk';
import { getUserId } from '../../../app/auth';
import { useSelector } from 'react-redux';
import PuffLoader from "react-spinners/PuffLoader";
import TextAreaModal from '../../modals/text-area';
import ViewInteractionModal from '../../modals/view-interaction';
import PipelineTicketCard from '../../ticket-card/ticket-card-pipeline';
import TicketCardWrapper from '../../ticket-card/ticket-card-wrapper';
import moment from 'moment';
import AttachmentsIcon from '../../../img/icons/attachments.svg'
import InfoTag from '../../info-tag';
import NoteOutlineThali from '../../../img/noteOutlineThinThali02.svg';
import NoteOrange from '../../../img/note.svg';
import NoteOutlineFilled from '../../../img/noteOutlineThali02.svg';
import { hasManagerAccess, getName } from '../../../assigneeData';
import CheckOutModal from '../../modals/check-out';
import ExpandedLogModal from '../../modals/expanded-log';
import greenTick from '../../../img/greenTickFilled.svg'
import statusError from '../../../img/icons/statusError.svg'
import timerIcon from '../../../img/timer.svg'
import CheckInTimerTag from './checkin-timer-tag';
import ProspectForm from '../prospect-form';
import ProspectRibbon from '../user-ribbon';
import AssignProspectModal from '../../modals/assign-prospect';
import BurgerIcon from '../../../img/burgerAvatar.svg';
import FileIcon from '../../../img/icons/fileSmall.svg';
import RejectIcon from '../../../img/icons/statusErrorFilled.svg';
import RejectIconDisabled from '../../../img/icons/statusErrorDisabled.svg';
import ApproveIcon from '../../../img/greenTickFilled.svg';
import ApproveIconDisabled from '../../../img/icons/tickDisabled.svg';
import RestoreIcon from '../../../img/icons/reload.svg'

const PipelineOverview = ({setHeaderTitle, setLeftHeaderSlot, setRightHeaderSlot}) => {
  const authData = useSelector((state: any) => state.auth);
  const token = authData.token;
  const userId = getUserId(token)
  const userName = `${authData.userData.first_name} ${authData.userData.last_name}`
  const {id}: {id: string} = useParams();
  const staffList = useSelector((state: any) => state.staff);

  const [checkinData, setCheckinData] = useState<any>(null);
  const [interactionNoteModalOpen, setInteractionNoteModalOpen] = useState(false);
  const [checkOutModalOpen, setCheckOutModalOpen] = useState(false);
  const [selectedInteraction, setSelectedInteraction] = useState(null);
  const [prospectData, setProspectData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedNote, setSelectedNote] = useState<any>({});
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [updatePayload, setUpdatePayload] = useState<any>({});
  const [assigneeModalOpen, setAssigneeModalOpen] = useState(false);
  const [assignee, setAssignee] = useState<string>();
  const [assigneeChanged, setAssigneeChanged] = useState(false);
  const [rejectProspectModalOpen, setRejectProspectModalOpen] = useState(false);

  const isMobile = window.innerWidth < 768;
  const userAuthorised = hasManagerAccess(userId);

  const loadProspectData = () => {
    Admin.getProspect(userId, parseInt(id))
      .then(res => {
        if (res) {
          setHeaderTitle(res.shop_name);
          setAssignee(res.assignee)

          var filteredIntertactions = res.interactions.filter(interaction => parseInt(interaction.reason_id) < 6);
          var latestVisit = filteredIntertactions[filteredIntertactions.length - 1];

          if (latestVisit && latestVisit?.created_at === latestVisit?.updated_at) {
            setCheckinData({
              name: getName(staffList, latestVisit?.created_by),
              time: latestVisit?.created_at
            });
          } else setCheckinData(null);

          setProspectData(res);

          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error)
        setError(true);
        setLoading(false);
      });
  }

  const addNoteButton = lockedOut => (
    <Button
      buttonText='Add Note'
      type="quaternary"
      onClick={() => setInteractionNoteModalOpen(true)}
      disabled={saveEnabled || assigneeChanged || loading || lockedOut}
      icon={!isMobile ? (saveEnabled || assigneeChanged || loading || lockedOut) ? NoteOutlineThali : NoteOrange : ''}
      iconAltText={!isMobile ? 'Add note' : ''}
      condensed />
  )

  const checkInButton = lockedOut => (
    checkinData?.name ? (
      <Button
        type={isMobile ? "brand" : "danger"}
        buttonText='Check Out'
        onClick={() => setCheckOutModalOpen(true)}
        disabled={saveEnabled || assigneeChanged || loading || lockedOut}
        condensed
        customStyles={{width: 120}} />
    ) : (
      <Button
        buttonText='Check In'
        onClick={() => handleCheckIn()}
        disabled={saveEnabled || assigneeChanged || loading || lockedOut}
        icon={saveEnabled || loading || lockedOut ? doorIconThali : doorIconWhite}
        iconAltText='Check in button'
        condensed
        customStyles={{width: 120}} />
    )
  )

  const saveChangesButton = () => (
    <Button
      buttonText='Save Changes'
      onClick={() => saveChanges()}
      disabled={(!saveEnabled && !assigneeChanged) || loading || (!userAuthorised && ['6', '8'].includes(prospectData.status_id))}
      condensed />
  )

  useEffect(() => {
    loadProspectData();
  }, []);

  const getReviewButtons = () => {
    if (['5', '7'].includes(prospectData?.status_id)) {
      return (
        <>
          <Button
            buttonText={isMobile ? '' : 'Reject'}
            onClick={() => setRejectProspectModalOpen(true)}
            icon={saveEnabled || loading ? RejectIconDisabled : RejectIcon}
            iconAltText='Reject Prospect'
            type='quaternary'
            condensed
            variant={isMobile ? 'square' : undefined}
            disabled={saveEnabled || loading} />

          <Button
            buttonText={isMobile ? '' : 'Approve'}
            onClick={() => reviewProspect(prospectData?.status_id === '7' ? 2 : 1)}
            icon={saveEnabled || loading ? ApproveIconDisabled : ApproveIcon}
            iconAltText='Approve Prospect'
            type='quaternary'
            condensed
            variant={isMobile ? 'square' : undefined}
            disabled={saveEnabled || loading} />
        </>
      )
    } else {
      return (
        <Button
          buttonText="Restore"
          onClick={() => reviewProspect(5)}
          icon={RestoreIcon}
          iconAltText='Restore rejected prospect'
          condensed
          disabled={saveEnabled || loading} />
      )
    }
  }

  const handleSetRightHeaderSlot = () => {
    var lockedOut = !userAuthorised && ['6', '8'].includes(prospectData?.status_id);

    setRightHeaderSlot(
      !isMobile ? (
        <>
          {saveChangesButton()}
          {addNoteButton(lockedOut)}
          {userAuthorised && ['5', '6', '7', '8'].includes(prospectData?.status_id)
            ? getReviewButtons()
            : checkInButton(lockedOut)}
        </>
      ) : saveEnabled || assigneeChanged ? (
        saveChangesButton()
      ) : (
        <>
          {addNoteButton(lockedOut)}
          {userAuthorised && ['5', '6', '7', '8'].includes(prospectData?.status_id)
            ? getReviewButtons()
            : checkInButton(lockedOut)}
        </>
      )
    )
  }

  useEffect(() => {
    {window.innerWidth >= 900 && prospectData?.assignee_first_name && setLeftHeaderSlot(
      <Button
        onClick={() => setAssigneeModalOpen(true)}
        buttonText={`${getName(staffList, assignee, 'initial')}.`}
        icon={BurgerIcon}
        iconAltText='Open assignee modal'
        type='tertiary'
        condensed
        disabled={!userAuthorised} />
    )}

    handleSetRightHeaderSlot();
  }, [saveEnabled, checkinData, loading, updatePayload, assignee, assigneeChanged]);

  useEffect(() => {
    if (assignee?.toString() === prospectData?.assignee) return setAssigneeChanged(false);
    return setAssigneeChanged(true);
  }, [assignee, prospectData]);

  const saveChanges = (prospectClosed = null) => {
    setLoading(true);
    setSaveEnabled(false);

    let prospectStatus;
    if (prospectClosed === true && prospectData?.status_id === '5') prospectStatus = '7';
    else if (prospectClosed === true) prospectStatus = '2';
    else if (prospectClosed === false) prospectStatus = '3';
    else prospectStatus = prospectData?.status_id;

    updatePayload.status = prospectStatus;
    updatePayload.assignee = assignee;

    Admin.updateProspect(id, updatePayload)
      .then(() => {
        loadProspectData();
      })
      .catch(error => {
        alert(error);
        setLoading(false);
      });
  };

  const reviewProspect = (status, rejectionNote?) => {
    setLoading(true);

    let note;
    let reasonId;

    if (status === 5) {
      note = `${prospectData?.shop_name} has been reinstated by ${userName}.`;
      reasonId = 9;
    } else if ([6, 8].includes(status)) {
      note = rejectionNote;
      reasonId = 8;
    } else {
      note = `${prospectData?.shop_name} has been reviewed and approved by ${userName}.`;
      reasonId = 7;
    }

    var tempProspectData = updatePayload;
    tempProspectData.status = status;

    Admin.updateProspect(id, tempProspectData)
      .then(() => {
        handleAddNote(note, false, reasonId);
      })
      .catch(error => {
        alert(error);
        setLoading(false);
      });
  }

  const handleRejectProspect = (note, closeModal) => {
    setRejectProspectModalOpen(false);

    if (!closeModal) {
      reviewProspect(prospectData.status_id === '7' ? 8 : 6, note)
    }
  }

  const handleLocationError = errorCode => {
    switch (errorCode) {
      case 1:
        return alert('Error: Geolocation permission denied, please check your device settings');
      case 3:
        return alert('Error: Geolocation timed out, please try again');
      default:
        return alert('Error: Unable to get your location, please try again');
    }
  }

  const handleCheckIn = () => {
    setLoading(true);

    navigator.geolocation.getCurrentPosition(pos => {
          if (pos) {
            var interactionData = {
              reasonId: '',
              lat: pos.coords.latitude,
              long: pos.coords.longitude,
            }
            Admin.createInteraction(id, interactionData)
              .then(() => loadProspectData())
              .catch(error => {
                alert(error);
                setError(true)
                setLoading(false);
              });
          }
        }, error => handleLocationError(error));
  }

  const handleCheckout = (forceReload, checkOutData) => {
    setCheckOutModalOpen(false);

    if (forceReload) {
      setLoading(true);
      const checkInData = prospectData?.interactions.find(interaction => interaction.reason_id === '0');

      navigator.geolocation.getCurrentPosition(pos => {
        if (pos) {
          var interactionData = {
            reasonId: checkOutData.selectedReason,
            visitProof: [checkOutData.decodedImage],
            notes: checkOutData.description,
            lat: pos.coords.latitude,
            long: pos.coords.longitude,
          }

          Admin.updateInteraction(checkInData.prospect_id, checkInData.id, interactionData)
            .then(() => {
              if (checkOutData.prospectWonLost !== null) {
                saveChanges(checkOutData.prospectWonLost)
              }
              else loadProspectData();
            })
            .catch(error => {
              alert(error);
              setError(true);
              setLoading(false);
            });
        }
      }, error => handleLocationError(error));
    }
  }

  const handleAddNote = (note, closeModal, reasonId?) => {
    setInteractionNoteModalOpen(false);

    if (!closeModal) {
      setLoading(true);

      navigator.geolocation.getCurrentPosition(pos => {
        if (pos) {
          var interactionData = {
            reasonId: '',
            lat: pos.coords.latitude,
            long: pos.coords.longitude,
            notes: note
          }
          Admin.createInteraction(id, interactionData)
            .then(res => {
              Admin.updateInteraction(id, res.data, {reasonId: reasonId ?? 6, lat: pos.coords.latitude,long: pos.coords.longitude, notes: note})
                .then(() => loadProspectData())
                .catch(error => {
                  alert(error);
                  setError(true);
                  setLoading(false);
                });
            })
            .catch(error => {
              handleLocationError(error);
              setLoading(false);
            });
        }
      });
    }
  }

  const getExpandedLogModalTitle = reasonId => {
    if (reasonId === '6') return 'Note Added to Account';
    else if (reasonId === '8') return 'Prospect Rejected'
    else return getReasonCode(selectedNote?.reason_id);
  }

  const getProspectStatus = () => {
    var filteredIntertactions = prospectData?.interactions.filter(interaction => moment(interaction.updated_at).diff(moment(interaction.created_at)) > 2000);
    return filteredIntertactions[filteredIntertactions.length - 1]?.reason_id;
  }

  const getStatusTag = () => {
    var prospectStatus = getProspectStatus();

    if (['2', '7', '8'].includes(prospectData?.status_id)) return <Tag text='Closed' size='large' icon={greenTick} altText='Prospect closed' type='success' />
    else if (['3', '6'].includes(prospectData?.status_id)) return <Tag text='Closed' size='large' icon={statusError} altText='Prospect closed' type='fmo' />
    else if (!prospectStatus) return <Tag text={'New Prospect'} size='large' type="creme-brulee" />
    else return <Tag text={getReasonCode(prospectStatus)} size='large' type='highlight' icon={timerIcon} altText='Timer icon' />
  }

  const secondTagRow = () => (
    <>
      <Tag text={`Longitude ${prospectData?.long?.trim()}`} type='date-grey' size='large' />
      <Tag text={`Latitude ${prospectData?.lat?.trim()}`} type='date-grey' size='large' />
    </>
  )

  return (
    <>
      <TextAreaModal
        title='Add Note'
        isOpen={interactionNoteModalOpen}
        setClosed={(note, closeModal) => handleAddNote(note, closeModal)}
        shopName={prospectData?.shop_name}
        buttonText="Add Note to Prospect" />

      <CheckOutModal
        isOpen={checkOutModalOpen}
        setClosed={(forceReload, checkOutData) => handleCheckout(forceReload, checkOutData)}
        setLoading={setLoading}
        shopName={prospectData?.shop_name} />

      <ViewInteractionModal
        isOpen={!!selectedInteraction}
        setClosed={() => setSelectedInteraction(null)}
        selectedInteraction={selectedInteraction} />

      <ExpandedLogModal
        isOpen={!!selectedNote?.reason_id}
        data={{
          logTitle: getExpandedLogModalTitle(selectedNote?.reason_id),
          logContent: selectedNote?.notes,
          logCreated: selectedNote?.created_at,
          logCreator: getName(staffList, selectedNote?.created_by) ?? 'Unknown',
        }}
        setClosed={() => setSelectedNote({})} />

      <AssignProspectModal
        setClosed={() => setAssigneeModalOpen(false)}
        isOpen={assigneeModalOpen}
        setAssignee={setAssignee}
        assignee={assignee}
        shopName={prospectData?.shop_name} />

      <TextAreaModal
        title='Reject Prospect'
        setClosed={(note, closeModal) => handleRejectProspect(note, closeModal)}
        isOpen={rejectProspectModalOpen}
        shopName={prospectData?.shop_name} />

      <div className='pipeline-overview'>

        {prospectData?.assignee_first_name && <ProspectRibbon checkinData={checkinData} user={`${getName(staffList, assignee, 'initial')}.`} onClick={() => !checkinData && setAssigneeModalOpen(true)} />}

        {!loading && !error ? (
          <div className='pipeline-overview__inner'>

            <div className='pipeline-overview__section'>
              {isMobile && ['5', '7'].includes(prospectData.status_id) && <div><Tag text='To Review' icon={FileIcon} altText='Prospect in review' type='required-default' size='large' /></div>}
              {isMobile && ['6', '8'].includes(prospectData.status_id) && <div><Tag text='Rejected' type='bubblegum' size='large' /></div>}

              <h1 className='pipeline-overview__title'>{prospectData?.shop_name}</h1>

              <div className='pipeline-overview__tag-wrapper pipeline-overview__tag-wrapper--column'>
                <div className='pipeline-overview__tag-wrapper'>
                  <Tag text={`Score: ${prospectData?.matrix_value}`} size='large' type="clementine" icon={matrixIcon} altText='Score' />
                  {getStatusTag()}
                  <Tag text={prospectData?.source.toLowerCase()} size='large' />
                  {isMobile
                    ? secondTagRow()
                    : checkinData?.name
                      ? <CheckInTimerTag checkinData={checkinData} />
                      :
                      <>
                        {!isMobile && ['5', '7'].includes(prospectData.status_id) && <Tag text='To Review' icon={FileIcon} altText='Prospect in review' type='required-default' size='large' customStyles={{marginLeft: 'auto'}} />}
                        {!isMobile && ['6', '8'].includes(prospectData.status_id) && <Tag text='Rejected' type='bubblegum' size='large' customStyles={{marginLeft: 'auto'}} />}
                      </>
                  }

                </div>
                {!isMobile &&
                  <div className='pipeline-overview__tag-wrapper'>
                    {secondTagRow()}
                  </div>
                }
              </div>

              <ProspectForm
                prospectData={prospectData}
                setSaveEnabled={setSaveEnabled}
                setFormData={setUpdatePayload} />
            </div>

            <div className='pipeline-overview__section pipeline-overview__section--timeline'>
              <h3 className='pipeline-overview__timeline-title'>Timeline</h3>

              <TicketCardWrapper>
                {prospectData.interactions.toReversed().map(interaction => {
                  var isAutoGenerated = ['7', '9'].includes(interaction.reason_id);
                  var isNote = ['6', '8'].includes(interaction.reason_id) || isAutoGenerated;

                  if (!isNote && interaction.created_at === interaction.updated_at) return;

                  const headerTags = (
                    <>
                      {isNote ?
                        <Tag text='LOG' type='eel' />
                        :
                        <>
                          <Tag text='VISIT LOG' type='blueberry' />
                          <Tag text={JSON.parse(interaction.visit_proof)?.length ?? 0} icon={AttachmentsIcon} altText="Number of attachments" />
                        </>
                      }
                    </>
                  );

                  const infoTag =
                    <InfoTag
                      icon={NoteOutlineFilled}
                      iconAltText="Note icon"
                      text="Left By"
                      tag2={<Tag type="creme-brulee" text={getName(staffList, interaction.created_by)?.toUpperCase() ?? ''} />}
                  />;

                  const infoTagAuto = <InfoTag icon={NoteOutlineFilled} iconAltText="Note icon" text="Auto Generated" />;
                  const footerTags = <Tag text={moment(interaction.updated_at).format('DD/MM/YY HH:mm')} type={'date'} />;

                  const getCardTitle = reasonId => {
                    if (reasonId === '6') return 'Note Added to Account'
                    else if (reasonId === '7') return 'Prospect Approved'
                    else if (reasonId === '8') return 'Prospect Rejected'
                    else if (reasonId === '9') return 'Prospect Restored'
                    else return getReasonCode(interaction.reason_id)
                  }

                  return (
                    <PipelineTicketCard
                      key={interaction.id}
                      onClick={() => setSelectedInteraction(interaction)}
                      type={isNote ? 'log' : 'visit-log'}
                      title={getCardTitle(interaction.reason_id)}
                      description={interaction.notes}
                      headerTags={headerTags}
                      infoTag={isAutoGenerated ? infoTagAuto : infoTag}
                      footerTags={footerTags}
                      selectNote={() => setSelectedNote(interaction)} />
                  )
                })}

                <PipelineTicketCard
                  type='log'
                  title='Prospect Created'
                  description={`New prospect added to Pipeline${prospectData.created_by !== '1' ? ` by ${getName(staffList, prospectData?.created_by, 'fullName')}` : ''}.`}
                  headerTags={<Tag text='LOG' type='eel' />}
                  infoTag={<InfoTag icon={NoteOutlineFilled} iconAltText="Note icon" text="Auto Generated" /> }
                  footerTags={<Tag text={moment(prospectData.created_at).format('DD/MM/YY HH:mm')} type={'date'} />} />

              </TicketCardWrapper>
            </div>
          </div>
        ) : !error ? (
          <div className="spinner-border">
            <PuffLoader
              size={75}
              color={"#D82B31"}
              loading={true}
            />
          </div>
        ) : (
          <p>Unable to load prospect data. Please try again.</p>
        )}
      </div>
    </>
  )
}

export default PipelineOverview;