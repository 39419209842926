import {useState, useEffect, CSSProperties} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {useSelector} from 'react-redux';

import Modal from "react-modal";

import AgentShopList from "./components/agent-shop-list";
import Login from "./components/login";

import useWindowSize from './app/helper/screen';

import "react-toggle/style.css";
import Overview from './components/overview';
import SideBar from './components/sidebar';
import NavBar from './components/navbar';
import NavbarMobile from './components/navbarMobile';
import CreateShop from './components/create';
import Stats from "./components/stats";
import AgentJobs from './components/jobs';
import Home from "./components/home";
import NavigationContext from "./NavigationContext";
import ShopPerformance from "./components/agent/shop-performance";
import { ICacheLike } from 'axios-extensions';
import ShopChecklist from "./components/agent/shop-checklist";
import Targets from "./components/agent/targets";
import SavingsCalculator from "./components/agent/savings-calculator";
import ScrollToTop from './components/scrollToTop';
import Breakdown from './components/overview/breakdown';
import InventoryManager from './components/inventory-manager';
import Pipeline from './components/pipeline';
import {Admin} from './fmo-api-sdk';
import {logout, updateToken} from "./app/slices/authSlice";
import { useDispatch } from "react-redux";
import { getRole, getExpiry, AUTH_FIELD, AUTH_MANAGER, AUTH_INVENTORY, getUserId } from "./app/auth";
import PipelineOverview from './components/pipeline/overview';
import NavBarNew from './components/nav-bar-new';
import NewProspect from './components/pipeline/new-prospect';
import { setStaff } from './app/slices/staffSlice';
import AgentOverviewTable from './components/agent-overview-table';
import Dashboard from './components/dashboard';

declare module 'axios' {
    interface AxiosRequestConfig {
        // if your cacheFlag was setting to 'useCache'
        cache?: boolean | ICacheLike<any>;
    }
}

Modal.setAppElement('#root');
type SearchTerm = {
    text: string
    type: string
}

function App() {

    const authData = useSelector((state: any) => state.auth);
    const user = authData?.userData;
    const userRole = authData?.token && getRole(authData?.token);
    const userId = authData?.token && getUserId(authData?.token);
    const dispatch = useDispatch();
    const refreshBufferTime = 120;
    const [expTime, setExpTime] = useState<any>();
    const [headerTitle, setHeaderTitle] = useState('');
    const [leftHeaderSlot, setLeftHeaderSlot] = useState();
    const [rightHeaderSlot, setRightHeaderSlot] = useState();
    const [pageTitle, setPageTitle] = useState();
    const [backEnabled, setBackEnabled] = useState();

    async function refreshToken() {
      await Admin.refreshToken()
        .then((res) => dispatch(updateToken(res)))
        .catch(error => console.log(error));
    }

    const getStaffObject = staffMember => ({
        name: `${staffMember.first_name} ${staffMember.last_name}`,
        value: staffMember.id
    });

    useEffect(() => {
      if (user) {
        Admin.getStaff()
          .catch(error => {
            console.log(error)
          })
          .then(res => {
            delete res['Not Set']
            let tempStaffArray;

            Object.entries(res).forEach(([key, value]: any) => {
              // filter staff by enabled flag
              tempStaffArray = {...tempStaffArray, [key]: value.filter(staffMember => staffMember.enabled === '1').map(staffMember => getStaffObject(staffMember))}
            });

            dispatch(setStaff(tempStaffArray));
          });
        }
    }, [user]);

    useEffect(() => {
      if (authData?.token) {
        setExpTime(getExpiry(authData?.token));

        var countdownDefault = expTime - Math.round(Date.now() / 1000) - refreshBufferTime;
        var countdownTimer = countdownDefault;

        const refeshCountdown = setInterval(async () => {
          countdownTimer--
          // console.log(countdownTimer);

          if (countdownTimer <= 0 && authData !== null) {
            refreshToken();
            countdownTimer = countdownDefault;
          };
        }, 1000);
        return () => clearInterval(refeshCountdown);
      }

      window.addEventListener('focus', handleTabRefocus);

      return () => {
        window.removeEventListener('focus', handleTabRefocus)
      };
    }, [authData, expTime]);

    function handleTabRefocus() {
      if (user && Math.round(Date.now() / 1000) >= expTime) refreshToken();
    }

    const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false);
    const [searchTerm, updateSearchTerm] = useState<SearchTerm>({
        text: '',
        type: 'name'
    });
    const [backFunction, setBackFunction] = useState();

    const updateSearch = (val, type) => updateSearchTerm({
        text: val,
        type
    });

    const handleFilterClose = () => setFilterIsOpen(false);

    const size = useWindowSize();

    const NavBarWrapper = ({withFilter = false}) => (
        <NavBar
            withFilter={withFilter}
            onFilterOpen={() => setFilterIsOpen(true)}
            updateSearch={updateSearch}
        />
    );

    interface Props {
      withFilter?: boolean,
      back?: boolean,
      search?: boolean,
      backFunction?: any,
      leftHeaderSlot?: JSX.Element
      rightHeaderSlot?: JSX.Element
      hideUser?: boolean
      customStyles?: CSSProperties
    }

    const NavBarMobileWrapper = ({withFilter = false, back = false, search = false, backFunction = undefined, leftHeaderSlot = undefined, rightHeaderSlot = undefined, hideUser=false, customStyles=undefined}: Props) => (
        <NavbarMobile 
          withFilter={withFilter}
          back={back}
          search={search}
          onFilterOpen={() => setFilterIsOpen(!filterIsOpen)}
          updateSearch={updateSearch}
          backFunction={backFunction}
          leftHeaderSlot={leftHeaderSlot}
          rightHeaderSlot={rightHeaderSlot}
          hideUser={hideUser}
          customStyles={customStyles}
        />
    );

    // below is hack for David/Manzoor/Alistair to see agent overview
    const renderAgentOverview = () => {
        if (userRole === AUTH_FIELD && !['132', '229', '130'].includes(userId)) return null;

        return (
            <Route key={104} path="/shops/agent-overview">
                {size.width !== undefined && size.width <= 768 ?
                    <NavBarMobileWrapper hideUser leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} /> : (
                    <>
                        <NavBarNew headerTitle='Agent Overview' leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} />
                        <SideBar back={false} activeNav={13}/>
                    </>
                )}
                <AgentOverviewTable setLeftHeaderSlot={setLeftHeaderSlot} setRightHeaderSlot={setRightHeaderSlot} />
            </Route>
        )
    };

    const renderLockedRoutes = () => {
        if (userRole === AUTH_FIELD) return null;

        return [(
            <Route key={101} path="/stats">
                {size.width !== undefined && size.width <= 768 ?
                    <NavBarMobileWrapper/> : (
                    <>
                        <NavBarWrapper/>
                        <SideBar back={false} activeNav={3}/>
                    </>
                )}
                <Stats/>
            </Route>
        ), (
            <Route key={102} path="/stats-agent-shops">
                {size.width !== undefined && size.width <= 768 ?
                    <NavBarMobileWrapper search /> : (
                    <>
                        <NavBarWrapper/>
                        <SideBar back activeNav={0}/>
                    </>
                )}
                <AgentShopList/>
            </Route>
        ), (
            <Route key={103} path="/stats-agent-region">
                {size.width !== undefined && size.width <= 768 ?
                    <NavBarMobileWrapper /> : (
                    <>
                        <NavBarWrapper/>
                        <SideBar back activeNav={0}/>
                    </>
                )}
                <AgentShopList showRegion/>
            </Route>
      ), (
            <Route key={8} path="/">
                {size.width !== undefined && size.width <= 768 ?
                    <NavBarMobileWrapper /> : (
                    <>
                        <NavBarWrapper />
                        <SideBar back={false} activeNav={0}/>
                    </>
                )}
                <Dashboard />
            </Route>
        ), (
          // move above previous route when enabling inventory manager feature
            <Route key={11} path="/inventory-manager">
                {size.width !== undefined && size.width <= 768 ?
                    <NavBarMobileWrapper back={backFunction ? true : false} backFunction={backFunction} /> : (
                    <>
                        <NavBarWrapper/>
                        <SideBar back={backFunction ? true : false} backFunction={backFunction} activeNav={11}/>
                    </>
                )}
                <InventoryManager setBackFunction={setBackFunction} />
            </Route>
        )];
    }

    return (
        <Router>
            <main className="main-content">
                {user ?
                    <NavigationContext.Provider value={{
                        searchTerm: searchTerm.text,
                        searchType: searchTerm.type,
                        updateSearch,
                        filterIsOpen,
                        filterClose: handleFilterClose
                    }}>
                        <ScrollToTop />
                        <Switch>
                            <Route key={1} path="/shop/create">
                                {size.width !== undefined && size.width <= 768 ?
                                <NavBarMobileWrapper/> : (
                                <>
                                    <NavBarWrapper/>
                                    <SideBar back={false} activeNav={1}/>
                                </>
                                )}
                                <CreateShop/>
                            </Route>
                            <Route key={2} path="/overview/:id">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper
                                      back
                                      leftHeaderSlot={leftHeaderSlot}
                                      rightHeaderSlot={rightHeaderSlot}
                                      hideUser /> : (
                                    <>
                                        <NavBarNew back headerTitle={headerTitle} leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} />
                                        <SideBar back={false} activeNav={0}/>
                                    </>
                                )}
                                <Overview setHeaderTitle={setHeaderTitle} setLeftHeaderSlot={setLeftHeaderSlot} setRightHeaderSlot={setRightHeaderSlot} />
                            </Route>
                            <Route key={4} path="/jobs">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper hideUser leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} customStyles={{borderBottomColor: '#E1DFDF'}} /> : (
                                    <>
                                        <NavBarNew headerTitle={'My Jobs'} leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} />
                                        <SideBar back={false} activeNav={2}/>
                                    </>
                                )}
                                <AgentJobs setLeftHeaderSlot={setLeftHeaderSlot} setRightHeaderSlot={setRightHeaderSlot} />
                            </Route>
                            <Route key={6} path="/shops/region">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper hideUser back={backEnabled} leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} /> : (
                                    <>
                                        <NavBarNew back={backEnabled} headerTitle={pageTitle ?? 'My Region'} leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} />
                                        <SideBar back={false} activeNav={5}/>
                                    </>
                                )}
                                <AgentShopList title={pageTitle ?? 'My Region'} showRegion setLeftHeaderSlot={setLeftHeaderSlot} setRightHeaderSlot={setRightHeaderSlot} setPageTitle={setPageTitle} />
                            </Route>
                            <Route key={7} path="/shops/problem">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper hideUser leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} /> : (
                                    <>
                                        <NavBarNew headerTitle='My Problem Shops' leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} />
                                        <SideBar back={false} activeNav={6}/>
                                    </>
                                )}
                                <AgentShopList title="My Problem Shops" showRegion setLeftHeaderSlot={setLeftHeaderSlot} setRightHeaderSlot={setRightHeaderSlot} problemShops/>
                            </Route>
                            {/* <Route key={5} path="/agent/shop-performance">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper search /> : (
                                    <>
                                        <NavBarWrapper/>
                                        <SideBar back={false} activeNav={7}/>
                                    </>
                                )}
                                <ShopPerformance title="Shop Performance"/>
                            </Route>
                            <Route key={5.5} path="/agent/shop-checklist">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper search /> : (
                                    <>
                                        <NavBarWrapper/>
                                        <SideBar back={false} activeNav={8}/>
                                    </>
                                )}
                                <ShopChecklist title="Checklist"/>
                            </Route> */}
                            <Route key={6} path="/agent/targets">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper/> : (
                                    <>
                                        <NavBarWrapper/>
                                        <SideBar back={false} activeNav={9}/>
                                    </>
                                )}
                                <Targets title="Targets"/>
                            </Route>
                            <Route key={10} path="/agent/savings-calculator">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper/> : (
                                    <>
                                        <NavBarWrapper/>
                                        <SideBar back={false} activeNav={10}/>
                                    </>
                                )}
                                <SavingsCalculator />
                            </Route>
                            <Route key={9} path="/breakdown">
                                <Breakdown />
                            </Route>
                            <Route key={12} path="/pipeline">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper
                                      back={backFunction ? true : false}
                                      backFunction={backFunction}
                                      leftHeaderSlot={leftHeaderSlot}
                                      rightHeaderSlot={rightHeaderSlot}
                                      hideUser /> : (
                                    <>
                                        <NavBarNew headerTitle='Pipeline' leftHeaderSlot={leftHeaderSlot} rightHeaderSlot={rightHeaderSlot} />
                                        <SideBar back={false} activeNav={12}/>
                                    </>
                                )}
                                <Pipeline setLeftHeaderSlot={setLeftHeaderSlot} setRightHeaderSlot={setRightHeaderSlot} />
                                {/* add this to pipeline overview? */}
                            </Route>
                            <Route key={13} path="/prospect-overview/:id">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper
                                      back
                                      backFunction={backFunction}
                                      rightHeaderSlot={rightHeaderSlot}
                                      hideUser
                                    />
                                    : (
                                    <>
                                        <NavBarNew
                                          headerTitle={headerTitle}
                                          back
                                          leftHeaderSlot={leftHeaderSlot}
                                          rightHeaderSlot={rightHeaderSlot} />
                                        {size.width !== undefined && size?.width >= 900 && <SideBar back={false} activeNav={12}/>}
                                    </>
                                )}
                                <PipelineOverview setHeaderTitle={setHeaderTitle} setLeftHeaderSlot={setLeftHeaderSlot} setRightHeaderSlot={setRightHeaderSlot} />
                            </Route>
                            <Route key={14} path="/new-prospect">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper
                                      back
                                      backFunction={backFunction}
                                      rightHeaderSlot={rightHeaderSlot}
                                      hideUser
                                    />
                                    : (
                                    <>
                                        <NavBarNew
                                          headerTitle={headerTitle}
                                          back
                                          leftHeaderSlot={leftHeaderSlot}
                                          rightHeaderSlot={rightHeaderSlot} />
                                        <SideBar back={false} activeNav={0}/>
                                    </>
                                )}
                                <NewProspect setHeaderTitle={setHeaderTitle} setLeftHeaderSlot={setLeftHeaderSlot} setRightHeaderSlot={setRightHeaderSlot} />
                            </Route>
                            {renderAgentOverview()}
                            {renderLockedRoutes()}
                            <Route key={3} path="/">
                                {size.width !== undefined && size.width <= 768 ?
                                    <NavBarMobileWrapper/> : (
                                    <>
                                        <NavBarWrapper/>
                                        <SideBar back={false} activeNav={0}/>
                                    </>
                                )}
                                <Dashboard/>
                            </Route>
                        </Switch>
                    </NavigationContext.Provider>
                    : <Login />
                }
            </main>

        </Router>
    );
}

export default App;
